<template>
  <div class="sidebar-manage">
    <div class="sidebar-manage__content">
      <div class="sidebar-manage__top">
        <h2 class="title title--big sidebar-manage__title">{{ title }}</h2>
        <button @click="$emit('close-sidebar')" class="sidebar-manage__close">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.3571 15.5L8 10.1429L2.64286 15.5C2.05357 16.0893 1.08929 16.0893 0.5 15.5C-0.0892856 14.9107 -0.0892856 13.9464 0.5 13.3571L5.85714 8L0.5 2.64286C-0.0892858 2.05357 -0.0892856 1.08929 0.5 0.5C1.08929 -0.0892859 2.05357 -0.0892854 2.64286 0.5L8 5.85714L13.3571 0.5C13.9464 -0.0892854 14.9107 -0.0892859 15.5 0.5C16.0893 1.08929 16.0893 2.05357 15.5 2.64286L10.1429 8L15.5 13.3571C16.0893 13.9464 16.0893 14.9107 15.5 15.5C14.9107 16.0893 13.9464 16.0893 13.3571 15.5Z"
              fill="#2B93E7"/>
          </svg>
        </button>
      </div>
      <slot>Тут должен быть контент сайдбара</slot>
    </div>
    <div @click="$emit('close-sidebar')" class="sidebar-manage__holder"></div>
  </div>
</template>

<script>
export default {
  props: ['title']
};
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.sidebar-manage
  position: fixed
  top: 0
  right: 0
  z-index: 100
  width: 100%
  height: 100%
  pointer-events: none

.sidebar-manage--full
  pointer-events: auto

  .sidebar-manage__content
    transform: translateX(0%)

  .sidebar-manage__holder
    opacity: 0.5

.sidebar-manage__content
  z-index: 1
  overflow-y: auto
  overflow-x: hidden
  position: absolute
  top: 0
  right: 0
  width: 500px
  height: 100%
  padding: 50px 40px
  background-color: #f8fdff
  box-shadow: 0 4px 14px rgba(172, 196, 206, 0.4)
  transform: translateX(100%)
  transition: transform 0.5s
  scrollbar-color: $color-theme #f2f3f8
  scrollbar-width: thin

  +max-w($tablet)
    padding: 30px 20px

  +max-w($mobile_md)
    width: 330px
    padding: 20px 10px

  +max-w($mobile_sm)
    width: 300px

  &::-webkit-scrollbar
    width: 2px

  &::-webkit-scrollbar-track
    border-radius: 5px
    background-color: #f2f3f8

  &::-webkit-scrollbar-thumb
    border-radius: 5px
    background-color: $color-theme

.sidebar-manage__title
  flex-shrink: 1

.sidebar-manage__holder
  position: absolute
  top: 0
  right: 0
  width: 100%
  height: 100%
  background-color: #000
  opacity: 0
  transition: opacity 0.3s

.sidebar-manage__top
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 30px

.sidebar-manage__close
  svg path
    transition: fill 0.3s

  &:hover
    svg path
      fill: #49B1F5

  &:active
    svg path
      fill: #177FD3

.sidebar-manage__form
  margin-bottom: 30px
</style>